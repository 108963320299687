import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import VmTable from "../../components/shared-table";
import VmButton from "../../components/shared-button";
import { ApiDataListType, ApiDataResponseListLengthCheck, CRUDOperation, PAGE_SIZE } from "../../constants/options";
import Breadcrumb from "../../components/shared-breadcrumb";
import VmModal from "../../components/shared-modal";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { INLINE_SELECTED, INLINE_SELECTED_OPTION, INLINE_SELECT_OPTION, ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

const QuestionCategoryIndexPage = observer(() => {
  const { rootStore, academicStore, questionStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [currentSubject, setCurrentSubject] = useState<number>(0);
  const [targetQuestionCategory, setTargetQuestionCategory] = useState<any>();
  const [categoryCode, setCategoryCode] = useState<string>("");
  const [categoryDescription, setCategoryDescription] = useState<string>("");
  const [subjectId, setSubjectId] = useState<string>("0");
  const [searchSubjectKey, setSearchSubjectKey] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const onChangeSubject = (event: SelectChangeEvent) => {
    setSubjectId(event.target.value as string);
  };

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) != academicStore.subjectList.data.length) {
      academicStore.getSubjectList(0, 0)
        .then((res) => onToggleQuestionCatList(res.data[0].id));
    } else {
      onToggleQuestionCatList(academicStore.subjectList.data[0].id)
    }
  }, []);

  const onToggleQuestionCatList = (id: number, skip?: number) => {
    questionStore.getQuestionCategoryListBySubjectId(id, PAGE_SIZE, skip ? skip : 0);
    setCurrentSubject(id);
  }

  const onActiveQuestionCategory = () => {
    let req: any = {
      id: 0,
      categoryCode,
      categoryDescription,
      subjectId,
    }
    if (targetQuestionCategory) {
      req.id = targetQuestionCategory.id;
      questionStore.updateQuestionCategory(req).then(() => {
        rootStore.notify(t('QUESTION_CATEGORY') + t('UPDATED_B'), 'success');
        onToggleQuestionCatList(currentSubject);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      questionStore.createQuestionCategory(req).then(() => {
        rootStore.notify(t('QUESTION_CATEGORY') + t('CREATED_B'), 'success')
        onToggleQuestionCatList(currentSubject);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const handleShowModal = (id?: any) => {
    if (id) {
      let target = questionStore.questionCatList.data.find(qc => qc.id == id);
      setTargetQuestionCategory(target);
      setCategoryCode(target.categoryCode);
      setCategoryDescription(target.categoryDescription);
      setSubjectId(target.subjectId);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetQuestionCategory(null);
    setCategoryCode("");
    setCategoryDescription("");
    setSubjectId("0");
  }

  const handleDeleteModal = (id: number) => {
    let target = questionStore.questionCatList.data.find(qc => qc.id == id);
    setTargetQuestionCategory(target);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName={t('QUESTION_CATEGORY')}
      pageHeading={t('QUESTION_CATEGORY')}
      breadCrumb={[
        { label: t('QUESTION') + t('SETTING_B'), to: "/question/overview" },
        { label: t('CATEGORY'), to: "/question/category" }
      ]}
      buttonLabel={t('ADD_A') + t('QUESTION_CATEGORY')}
      onClickAction={() => handleShowModal()}
    >
      <div className="mb-4" style={ITEM_PERFECT_INLINED}>
        <TextField
          value={searchSubjectKey}
          label={t('SEARCH_SUBJECT')}
          variant="outlined"
          sx={{ width: 300, background: 'white', marginRight: 2 }}
          onChange={(value) => setSearchSubjectKey(value.target.value)}
          size="small"
        />
        <Typography>{t('SUBJECT_COUNT')}: {academicStore.subjectList.data.filter(sub => searchSubjectKey !== "" ?
          sub.subjectCode.toLowerCase().includes(searchSubjectKey.toLowerCase()) : sub.subjectCode !== "").length}</Typography>
      </div>

      <div className="grid grid-cols-4 gap-4">
        <div className="max-h-96 overflow-auto">
          {academicStore.subjectList.data.length > 0 &&
            academicStore.subjectList.data.filter(sub => searchSubjectKey !== "" ?
              sub.subjectCode.toLowerCase().includes(searchSubjectKey.toLowerCase()) : sub.subjectCode !== "")
              .map((sub: any, index: number) => (
                <Box key={`subject_select_${index}`}>
                  <button
                    type="button"
                    className={`${currentSubject == sub.id ? INLINE_SELECTED_OPTION : INLINE_SELECT_OPTION}`}
                    onClick={() => onToggleQuestionCatList(sub.id)}
                  >
                    {sub.subjectCode}
                  </button>
                </Box>
              ))}
        </div>
        <div className="col-span-3 max-h-96 overflow-auto">
          <Typography variant="subtitle2" marginBottom={1}>{t('SUBJECT') + t('DESCRIPTION_B')}: {currentSubject != 0 && academicStore.subjectList.data.find(s => s.id == currentSubject).subjectDescription}</Typography>
          <VmTable
            loading={questionStore.loading}
            page={page}
            paginationCount={questionStore.questionCatList.totalCount == 0 ? 1 : questionStore.questionCatList.totalCount / PAGE_SIZE}
            onChangePagination={(event, pageNumber) => {
              setPage(pageNumber);
              onToggleQuestionCatList(currentSubject, (pageNumber - 1) * PAGE_SIZE);
            }}
            thead={["ID", t('CATEGORY') + t('CODE_B'), t('CATEGORY') + t('DESCRIPTION_B'), t('ACTION')]}
            tbody={questionStore.questionCatList.data.length > 0 &&
              questionStore.questionCatList.data.map((qc: any, index: number) => (
                <>
                  <Box marginY={1} />
                  <tr key={`question_cat_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{qc.categoryCode}</td>
                    <td className={TD_NORMAL}>{qc.categoryDescription}</td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        onClick={() => handleShowModal(qc.id)}
                      >
                        {t('EDIT')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteModal(qc.id)}
                      >
                        {t('DELETE')}
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))}
          />
        </div>
      </div>

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetQuestionCategory ? t('UPDATE_A') + t('QUESTION_CATEGORY') : t('ADD_A') + t('QUESTION_CATEGORY')}
        buttonLabel={targetQuestionCategory ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetQuestionCategory ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActiveQuestionCategory}
        disabledCondition={academicStore.subjectList.length < 1 || categoryCode === "" || categoryDescription === ""}
      >
        <TextField
          value={categoryCode}
          label={t('CATEGORY') + t('CODE_B')}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setCategoryCode(value.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel>{t('SUBJECT')}</InputLabel>
          {
            academicStore.subjectList.data.length > 0 &&
            <Select
              label={t('SUBJECT')}
              sx={{ width: '100%', marginBottom: 2 }}
              value={subjectId}
              onChange={onChangeSubject}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {academicStore.subjectList.data.map((subject: any, index: number) => (
                <MenuItem key={`subject_select_${index}`} value={subject.id}>
                  {subject.subjectCode}
                </MenuItem>
              ))}
            </Select>
          }
        </FormControl>
        <TextField
          value={categoryDescription}
          label={t('CATEGORY') + t('DESCRIPTION_B')}
          variant="outlined"
          sx={{ width: '100%' }}
          rows={2}
          multiline
          onChange={(value) => setCategoryDescription(value.target.value)}
        />
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => {
          questionStore.deleteQuestionCategory(targetQuestionCategory.id).then(() => {
            rootStore.notify(t('QUESTION_CATEGORY') + t('DELETED_B'), 'success')
            onToggleQuestionCatList(currentSubject);
            handleCloseModal()
          }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))
        }}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_QUESTION_CAT')}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default QuestionCategoryIndexPage;
